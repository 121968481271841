#tender {
  position: relative;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  top: 200px;
  padding: 30px 90px;

  @media screen and (max-width: 1200px) {
    padding: 30px;
  }

  @media screen and (max-width: 1000px) {
    padding: 10px;
    padding-top: 0px;
  }
}

.app__tender {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.app__tender-flex-left {
  flex: 1;
  padding: 20px;
}

.app__tender-flex-left-title {
  padding: 10px;
  color: var(--navbar-color);
  font-size: 35px;
  text-align: center;
  flex: 1;
}

.app__tender-flex-left-content {
  align-items: center;
  justify-content: center;
  color: rgb(58, 58, 58);
  font-size: 16px;
  padding: 12px;
  line-height: 35px;
  text-align: justify;
  font-weight: 100;
  padding: 12px 25px;
  flex: 1;
}

.app__tender-flex-right-content {
  align-items: center;
  justify-content: center;
  color: rgb(58, 58, 58);
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  font-weight: 100;
  padding: 8px 25px;
  flex: 1;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: rgb(252, 252, 252);

  @media screen and (max-width: 600px) {
    max-width: 350px;
    margin: 0px;
  }

  .valueSum {
    background-color: rgb(164, 235, 164);
    border-radius: 5px;
    padding: 8px;
    color: black;
    font-weight: 600;
    border: 1px solid rgb(45, 210, 45);
  }
}

.app__tender-flex-right {
  flex: 1;
  padding: 20px;
  box-shadow: 10px gray;

  @media screen and (max-width: 500px) {
    max-width: 400px;
    margin: 0px;
  }
}

.app__tender-flex-right-images {
  margin: 20px;
  margin-top: 0px;

  .img {
    margin: 10px;
    border-radius: 12px;
    max-width: 500px;
    border: 1px solid rgb(216, 216, 216);

    @media screen and (max-width: 600px) {
      max-width: 300px;
      margin: 0px;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 0px;
  }
}

.imgFixed {
  position: fixed;
  top: 100px;
  left: 125px;
  z-index: 100;
  margin: 10px;
  border-radius: 12px;
  max-width: 350px;
  border: 1px solid rgb(216, 216, 216);
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    max-width: 200px;
    margin: 0px;
    left: 55px;
  }
}

.value {
  background: aquamarine;
  color: red;
}
