.app__navbar {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: var(--navbar-color);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid white;
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;

  img {
    width: 120px;
    height: 45px;

    @media screen and (min-width: 1800px) {
      width: 140px;
      height: 80px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-left: -100px;

  li {
    margin: 0 17px;
    cursor: pointer;
    flex-direction: column;

    div {
      width: 20px;
      height: 2px;
      background: transparent;
      border-radius: 0%;
      margin: 5px;
      transition: 0.3s all ease-in-out;
    }

    a {
      color: var(--white-color);
      text-decoration: none;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 500;
      transition: 0.3s all ease-in-out;
      border: 1.5px solid transparent;
      padding: 5px;
      font-size: 14px;

      @media screen and (max-width: 1000px) {
        font-size: 11px;
      }

      &:hover {
        color: rgb(239, 234, 234);
        border-bottom: 1.5px solid rgb(239, 234, 234);
        border-top: 1.5px solid rgb(239, 234, 234);
      }

      &:active {
        color: rgb(151, 151, 151);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  opacity: 1;

  svg {
    width: 70%;
    height: 70%;
    color: var(--navbar-color);
    cursor: pointer;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 6;
    padding: 10px;
    width: 80%;
    height: 100vh;
    opacity: 1;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background-color: whitesmoke;
    background-size: cover;
    background-repeat: repeat;

    box-shadow: 0 0 20px var(--lightGray-color);

    svg {
      width: 30px;
      height: 30px;
      color: var(--navbar-color);
      margin: 5px 10px;
      cursor: pointer;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: var(--navbar-color);

      li {
        margin: 35px;
        width: 100%;
        a {
          color: var(--navbar-color);
          font-size: 18px;
          padding: 20px;
          margin-bottom: 20px;
          width: 100%;
          transition: 0.3s all ease-in-out;

          &:hover {
            color: inherit;
            border-top: 1.5px solid var(--navbar-color);
            border-bottom: 1.5px solid var(--navbar-color);
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}
