#activity {
  background-color: rgb(248, 248, 248);
  position: relative;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  top: 80px;
  padding: 30px 90px;

  @media screen and (max-width: 1000px) {
    padding: 30px;
  }

  @media screen and (max-width: 800px) {
    padding: 10px;
  }
}

.app__activity {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.app__activity-flex-left {
  flex: 1;
  padding: 20px;
}

.app__activity-flex-left-title {
  padding: 10px;
  color: var(--navbar-color);
  font-size: 35px;
  text-align: center;
  flex: 1;
}

.app__activity-flex-left-content {
  align-items: center;
  justify-content: center;
  color: rgb(58, 58, 58);
  font-size: 16px;
  padding: 12px;
  line-height: 35px;
  text-align: justify;
  font-weight: 100;
  padding: 12px 25px;
  flex: 1;
}

.app__activity-flex-right {
  flex: 1;
  padding: 20px;
  box-shadow: 10px gray;

  @media screen and (max-width: 1500px) {
    display: none;
  }
}

.app__activity-flex-right-images {
  margin: 20px;

  .img {
    margin: 10px;
    border-radius: 12px;
    max-width: 700px;
    height: 400px;
    border: 1px solid rgb(218, 218, 218);
  }
}
