#about {
    position: relative;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    top: 80px;
    padding: 30px 90px;
    
    @media screen and (max-width: 1000px) {
        padding: 30px;
    }

    @media screen and (max-width: 800px) {
        padding: 10px;
        padding-top: 70px;
    }
}

.app__about {
    display: flex;
    flex: 1;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}

.app__about-flex-left {
    //border: 1px solid black;
    display: flex;
    flex: 4;
    //border: 1px solid black;
    padding: 20px;
}

.app__about-flex-left-title {
    //border: 1px solid black;
    flex: 1;
    padding: 10px;
    color: var(--navbar-color);
    font-size: 35px;
}

.app__about-flex-left-content {
    //border: 1px solid black;
    color: rgb(58, 58, 58);
    font-size: 16px;
    padding: 12px;
    line-height: 35px;
    text-align: justify;
    font-weight: 100;
}

.app__about-flex-right {
    display: flex;
    flex: 3;
    //border: 1px solid black;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .img {
        border-radius: 7px;
        border: 1px solid rgb(165, 165, 165);
        width: 600px;
        height: 400px;
    }

    @media screen and (max-width: 1800px) {
        display: none;
    }
}