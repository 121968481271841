#contact {
    background-color: rgb(248, 248, 248);
    position: relative;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    top: 80px;
    padding: 30px 90px;
  
    @media screen and (max-width: 1000px) {
      padding: 30px;
    }
  
    @media screen and (max-width: 800px) {
      padding: 10px;
    }
  }
  
  .app__contact {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  
  .app__contact-flex-left {
    flex: 1;
    padding: 20px;
    flex-direction: column;
  }

  .app_contact-flex-company {
    font-size: 22px;
    color: rgb(111, 130, 152);
    margin-bottom: 22px;
    text-align: center;
  }

  .app_contact-flex-phone {
    font-size: 20px;
    color: rgb(90, 90, 90);
    margin-bottom: 20px;
  }

  .app_contact-flex-email{
    font-size: 20px;
    color: rgb(90, 90, 90);
    margin-bottom: 30px;
  }
  
  .app__contact-flex-left-title {
    padding: 10px;
    color: var(--navbar-color);
    font-size: 35px;
    text-align: center;
    flex: 1;
  }
  
  .app__contact-flex-left-content {
    align-items: center;
    justify-content: center;
    color: rgb(58, 58, 58);
    font-size: 16px;
    padding: 12px;
    line-height: 35px;
    text-align: justify;
    font-weight: 100;
    padding: 12px 25px;
    flex: 1;
  }
  

  .download-button {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    font-size: 18px; /* Font size */
    font-weight: bold; /* Bold font */
    padding: 12px 24px; /* Padding for the button */
    border: none; /* No border */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: all 0.3s ease; /* Smooth transition */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between icon and text */
}

.download-button:hover {
    background-color: #45a049; /* Darker green on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Slightly larger shadow */
    transform: scale(1.05); /* Slightly larger */
}

.download-button:active {
    background-color: #3e8e41; /* Even darker green on click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow */
    transform: scale(0.98); /* Slightly smaller */
}